import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import ChatGroup from 'components/ChatGroup'
import ChatEntry from 'components/ChatEntry'
import Developer from 'components/Developer'

type Props = {
  dogsStore: Object,
  messages: Array
}

@inject('dogsStore')
@observer class ChatList extends Component {
  props: Props
  constructor (props) {
    super(props)

    this.state = {
      messages: null,
      areYouThereTimeout: null,
      developerMode: true
    }

    this._doAreYouThereTimeout = this._doAreYouThereTimeout.bind(this)
    this._getAvatar = this._getAvatar.bind(this)
  }

  componentDidMount () {
    this.setState( this.props )
    this.setState({ areYouThereTimeout: setInterval(this._doAreYouThereTimeout, (60 * 1000)) } )
  }

  componentWillReact() {
    const { dogsStore } = this.props
    this.setState({ messages: dogsStore.allMessages })
  }

  _doAreYouThereTimeout() {
    const { dogsStore } = this.props
    dogsStore.injectAreYouThere()
  }
  
  _getMessageGroups() {
    const { dogsStore } = this.props
    const { messages } = this.state
    let i, itm
    let prevId = null,
        group_id = 0,
        groups = [],
        messageGroupMessages = [],
        currentUser = messages[0].isUser || false,
        isLoading = false

    for (i = 0; i < messages.length; i++) {
      prevId = (itm) ? itm.id : null
      itm = messages[i]
      if (itm.type === 'NoEntry' && itm.isUser) continue
      if (itm.display) {
        if (currentUser !== itm.isUser) {
          groups.push({ 
            id: group_id,
            avatar: "",
            isMine: currentUser, 
            messages: messageGroupMessages,
            isLoading: false
          })
          currentUser = itm.isUser
          messageGroupMessages = [itm]
          group_id++
        } else {
          messageGroupMessages.push(itm)
        }
      } else {
        dogsStore.setCurrent(prevId)
        if (itm.delay > 0) {
          setTimeout(() => { dogsStore.enableNextMessage() }, itm.delay)
        }
        break
      }
    }
    groups.push({
      id: group_id,
      avatar: "",
      isMine: currentUser, 
      messages: messageGroupMessages,
      isLoading: isLoading
    })

    dogsStore.setCurrent(prevId)

    return groups.reverse()
  }

  _getAvatar(group) {
    if (group.avatar)
      return group.avatar
    if (group.isMine)
      return '/images/profile_user.png'
    return '/images/profile_admin.png'
  }

  _renderMessageGroups () {
    const { messages } = this.state
    if (messages && messages.length > 0) {
      return this._getMessageGroups().map((group) => {
        return (
          <ChatGroup key={`chat_group_${group.id}`} groupId={String(group.id)} avatar={this._getAvatar(group)} messages={group.messages} isMine={group.isMine} isLoading={group.isLoading} />
        )
      })
    }
    return []
  }

  _displayChatEntry () {
    const { messages } = this.state
    if (messages && messages.length > 0) {
      const lastMessage = messages[messages.length-1]
      if (lastMessage.type === 'texter') {
        return true
      }
    }
    return false
  }

  _displayDeveloper () {
    const { developerMode } = this.state
    if (developerMode) {
      return <Developer />
    }
    return null
  }

  render () {
    const { dogsStore, messages } = this.props
    let lastMessageId = null
    let lastMessageType = ""
    if (messages && messages.length > 0) {
      const lastMessage = dogsStore.getCurrentMessage()
      lastMessageId = lastMessage.id
      lastMessageType = lastMessage.type
    }
    
    return (
      <div className="message_list__wrapper">
        <div className="message_list__trigger">{dogsStore.triggeredTest}</div>
        {this._displayDeveloper()}
        <div className={`message_list ${this._displayChatEntry() ? 'message_list--entry' : ''}`}>
          {this._renderMessageGroups()}
        </div>
        {this._displayChatEntry() ? <ChatEntry questionId={lastMessageId} messageType={lastMessageType} /> : ''}
      </div>
    )
  }
}

export default ChatList
