import React from 'react'
import moment from 'moment'
import AilmentPicker from 'components/AilmentPicker'

type Props = {
  question: String,
  isRange: Boolean,
  onSave: Function
}

class DateSelector extends React.Component {
  props: Props
  constructor(props) {
    super(props)
    this.state = {
      question: "",
      isRange: false,
      dates: [],
      answers: [],
      onSave: null,
      errorMessage: ''
    }

    this._updateAnswer = this._updateAnswer.bind(this)
  }

  componentDidMount() {
    this.setState( this.props )
    let todaysDate = moment()
    let prevDate = moment('1968-01-01', 'YYYY-MM-DD')
    if (this.props.isRange) {
      this.setState({
        dates: [{ 
          value: prevDate.format('YYYY-MM-DD'), 
          display: prevDate.format('YYYY') 
        }, { 
          value: todaysDate.format('YYYY-MM-DD'), 
          display: todaysDate.format('MMMM D, YYYY') 
        }],
        answers: [{ 
          value: prevDate.format('YYYY-MM-DD'), 
          display: prevDate.format('YYYY') 
        }, { 
          value: todaysDate.format('YYYY-MM-DD'), 
          display: todaysDate.format('MMMM D, YYYY') 
        }]
      })
    } else {
      this.setState({
        dates: [{ 
          value: todaysDate.format('YYYY-MM-DD'), 
          display: todaysDate.format('MMMM D, YYYY') 
        }],
        answers: [{ 
          value: todaysDate.format('YYYY-MM-DD'), 
          display: todaysDate.format('MMMM D, YYYY') 
        }]
      })
    }
  }

  _updateAnswer(index, answer) {
    const { isRange, answers, errorMessage } = this.state
    // let dt = moment(answer, "MMMM D, YYYY").format('YYYY-MM-DD')
    answers[+index] = answer
    this.setState( answers )

    // TODO: If multiple answers need to check dates
    if (isRange) {
      let dtBeg = moment(answers[0].value, "YYYY-MM-DD")
      let dtEnd = moment(answers[1].value, "YYYY-MM-DD")

      if ( !dtEnd.isSameOrAfter(dtBeg) ) {
        this.setState({ errorMessage: 'Error, please make sure your end date is after your start date.' })
      }
    }
    if (errorMessage !== '') this.setState({ errorMessage: '' })
  }

  _getPickers() {
    const { isRange, dates } = this.state

    if (dates.length === 0) {
      return null
    }

    if (isRange) {
      return (
        <div>
          <AilmentPicker 
                key="dp_1"
                index="0"
                label="Start" 
                value={1}
                onUpdate={this._updateAnswer} />
          <AilmentPicker 
                key="dp_2"
                index="1"
                label="End"
                value={85}
                onUpdate={this._updateAnswer} />
        </div>
      )
    }

    return <AilmentPicker 
              key="dp_1"
              index="0"
              label="Start" 
              value={85}
              onUpdate={this._updateAnswer} />
  }

  _saveAnswers() {
    const { onSave, answers, errorMessage } = this.state

    if (errorMessage === '') {
      if (onSave) {
        onSave(answers)
      } else {
        console.log(answers)
      }
    }
  }

  _getErrorSaveArea() {
    const { errorMessage } = this.state
    if (errorMessage !== '') {
      return (
        <div className="dateSelector__error">
          {errorMessage}
        </div>
      )
    }
    return (
      <button className="btn--save" onClick={(e) => {
        e.preventDefault()
        this._saveAnswers()
      }}>Done</button>
    )
  }
 
  render() {
    const { question } = this.state
    return (
      <div className="dateSelector">
        <div className="dateSelector__container">
          <div ref={(question) => { this.question = question }} className="dateSelector__question" dangerouslySetInnerHTML={{__html: question}}></div>
          <div className="dateSelector__selectors">
            {this._getPickers()}
          </div>
        </div>
        <div className="dateSelector__lower">
          {this._getErrorSaveArea()}
        </div>
      </div>
    )
  }
}

export default DateSelector