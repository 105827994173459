import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import ChatList from 'components/ChatList'

type Props = {
  route: Object,
  dogsStore: Object
}

@inject('dogsStore')
@observer class Home extends Component {
  props: Props

  constructor (props) {
    super(props)

    this.state = {
      payload: {}
    }
  }

  componentDidMount() {
    const { dogsStore } = this.props
    dogsStore.fetchAllMessages()
      .then((payload) => {
        this.setState({ payload })
      })
  }

  render () {
    const { payload } = this.state
    if (payload.length > 0) {
      return (
        <ChatList messages={payload} />
      )
    }
    return null
  }
}

export default Home
