import React from 'react'
import { observer, inject } from 'mobx-react'

type Props = {
  dogsStore: Object,
  questionId: Number,
  messageType: String,
  currentDosage: String
}

@inject('dogsStore')
@observer class ChatEntry extends React.Component {
  props: Props
  constructor (props) {
    super(props)

    this.state = {
      textValue: "",
      questionId: null,
      messageType: "text",
      showDosage: false,
      currentDosage: "mg"
    }

    this._chooseDosageType
  }

  componentDidMount () {
    if (this.props.messageType) this.setState( { messageType: this.props.messageType } )
    if (this.props.questionId) this.setState( { questionId: this.props.questionId } )
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.messageType) this.setState( { messageType: nextProps.messageType } )
    if (nextProps.questionId) this.setState( { questionId: nextProps.questionId } )
  }

  _doSubmit() {
    const { dogsStore } = this.props
    const { questionId, messageType, currentDosage } = this.state
    let text = this.textEntry.value
    if (messageType === 'Dosage') {
      text = this.textEntry.value + ' ' + currentDosage
    }
    dogsStore.addUserTextEntry(questionId, text)
    this.textEntry.value = ''
  }

  // Added Dosage Scripts

  _doDosageView() {
    const { showDosage } = this.state
    this.setState({ showDosage: (showDosage) ? false : true })
  }

  _addDosage () {
    const { messageType, currentDosage } = this.state
    if (messageType === 'Dosage') {
      return (
        <div className="dosageChip">
          <a href="" onClick={(e) => {
            e.preventDefault()
            this._doDosageView()
          }}>
            <i className="fas fa-chevron-down"></i>
            {currentDosage}
          </a>
        </div>
      )
    }
    return null
  }

  _chooseDosageType(type) {
    this.setState({ 
      currentDosage: type,
      showDosage: false
    })
  }

  _getDosageList () {
    const dosageArray = ['mg', 'gram', 'mcg', 'drop', 'IU', 'mEq']
    let i = 0
    return dosageArray.map((itm) => {
      i++
      return (
        <li key={`dosage_type_${i}`}>
          <a href="#" className="dosageDropdown__menu__item" onClick={(e) => {
            e.preventDefault()
            this._chooseDosageType(itm)
          }}>{itm}</a>
        </li>
      )
    })
  }

  render () {
    const { messageType, showDosage } = this.state
    let type = "text"
    let placeholderText = "Start typing :)"
    if (messageType === 'RestrictedNumber') {
      type = "number"
      placeholderText = "How many times per day?"
    } else if (messageType === 'Dosage') {
      type = "number"
      placeholderText = "Enter dosage?"
    }
    return (
      <div>
        <div className={`dosageDropdown ${showDosage ? 'dosageDropdown--on' : ''}`}>
          <ul className="kill--list dosageDropdown__menu">
            {this._getDosageList()}
          </ul>
        </div>
        <div className="message_list__entry">
          <form onSubmit={(e) => { 
            e.preventDefault()
            this._doSubmit() 
          }}>
            <input name="textEntry" type={type} placeholder={placeholderText} ref={(textEntry) => { this.textEntry = textEntry }} />
            {this._addDosage()}
            <input type="submit" value="" />
          </form>
        </div>
      </div>
    ) 
  }
}

export default ChatEntry
