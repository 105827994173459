import React from 'react'

type Props = {
  question: String,
  options: Object,
  onSave: Function
}

class RestrictedNumber extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      question: null,
      options: null,
      textAnswer: "",
      displaySave: false,
      errorMessage: "",
      onSave: null
    }

    this._handleChange = this._handleChange.bind(this)
  }

  componentDidMount () {
    this.setState({ 
      question: this.props.question, 
      options: this.props.options, 
      onSave: this.props.onSave
    })
  }

  _handleChange(e) {
    const { displaySave } = this.state
    this.setState({ textAnswer: e.target.value })
    if (e.target.value === '') {
      if (displaySave) this.setState({ displaySave: false })
    } else {
      if (!displaySave) this.setState({ displaySave: true })
    }
  }

  _saveAnswers() {
    const { onSave, textAnswer, displaySave, errorMessage } = this.state

    if (displaySave && errorMessage === '') {
      if (onSave) {
        onSave([{ value: textAnswer }])
      } else {
        console.log(textAnswer)
      }
    }
  }

  _getErrorSaveArea() {
    const { displaySave, errorMessage } = this.state
    if (!displaySave) return null
    if (errorMessage !== '') {
      return (
        <div className="modal__error">
          {errorMessage}
        </div>
      )
    }
    return (
      <button className="btn--save" onClick={(e) => {
        e.preventDefault()
        this._saveAnswers()
      }}>Done</button>
    )
  }

  render () {
    const { question } = this.state
    if (question) {
      return (
        <div className="modal restrictedNumber">
          <div className="modal__container">
            <div ref={(question) => { this.question = question }} className="modal__question" dangerouslySetInnerHTML={{__html: question}}></div>
            <div className="modal__content">
              <input type="number" id="restrictredNumber" name="restrictredNumber" placeholder="How many times per day?" onChange={this._handleChange} />
            </div>
          </div>
          <div className="modal__lower">
            {this._getErrorSaveArea()}
          </div>
        </div>
      ) 
    }
    return null
  }
}

export default RestrictedNumber