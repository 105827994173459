import React from 'react'

type Props = {
  question: String,
  options: Object,
  onSave: Function
}

class Dosage extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      question: null,
      options: null,
      textAnswer: "",
      dosageAnswer: "mg",
      displaySave: false,
      errorMessage: "",
      onSave: null
    }

    this._handleDropdown = this._handleDropdown.bind(this)
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidMount () {
    this.setState({ 
      question: this.props.question, 
      options: this.props.options, 
      onSave: this.props.onSave
    })
  }

  _handleChange(e) {
    const { displaySave } = this.state
    this.setState({ textAnswer: e.target.value })
    if (e.target.value === '') {
      if (displaySave) this.setState({ displaySave: false })
    } else {
      if (!displaySave) this.setState({ displaySave: true })
    }
  }

  _handleDropdown(e) {
    this.setState({ dosageAnswer: e.target.value })
  }

  _saveAnswers() {
    const { onSave, textAnswer, dosageAnswer, displaySave, errorMessage } = this.state

    if (displaySave && errorMessage === '') {
      if (onSave) {
        onSave([{ value: textAnswer + ' ' + dosageAnswer }])
      } else {
        console.log(textAnswer + ' ' + dosageAnswer)
      }
    }
  }

  _getDosageList () {
    const dosageArray = ['mg', 'gram', 'mcg', 'drop', 'IU', 'mEq']
    let i = 0
    return dosageArray.map((itm) => {
      i++
      return (
        <option key={`dosage_type_${i}`}>{itm}</option>
      )
    })
  }

  _getErrorSaveArea() {
    const { displaySave, errorMessage } = this.state
    if (!displaySave) return null
    if (errorMessage !== '') {
      return (
        <div className="modal__error">
          {errorMessage}
        </div>
      )
    }
    return (
      <button className="btn--save" onClick={(e) => {
        e.preventDefault()
        this._saveAnswers()
      }}>Done</button>
    )
  }

  render () {
    const { question } = this.state
    if (question) {
      return (
        <div className="modal dosage">
          <div className="modal__container">
            <div ref={(question) => { this.question = question }} className="modal__question" dangerouslySetInnerHTML={{__html: question}}></div>
            <div className="modal__content">
              <input type="number" id="restrictredNumber" name="" placeholder="" onChange={this._handleChange} />
              <div className="selectBox">
                <select id="dosageType" name="dosageType" onChange={this._handleDropdown}>
                  {this._getDosageList()}
                </select>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </div>
          <div className="modal__lower">
            {this._getErrorSaveArea()}
          </div>
        </div>
      ) 
    }
    return null
  }
}

export default Dosage