import React from 'react'
import moment from 'moment'
import Slide from 'rc-slider'

// https://github.com/react-component/slider

type Props = {
  onSave: Function,
  min: Number,
  max: Number,
  defaultValue: Number
}

class Slider extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      onSave: null,
      min: null,
      max: null,
      defaultValue: null,
      values: { 
        start: { display: 0, value: 0 }
      }
    }
    this._onChange = this._onChange.bind(this)
    this._saveAnswers = this._saveAnswers.bind(this)
  }

  componentDidMount () {
    const { min, max, defaultValue, onSave } = this.props
    this.setState({ min, max, defaultValue, onSave, values: { start: this.getDateForStep(min) } })
  }

  getDateForStep(step) {
    const { max } = this.state

    let date
    let format = 'YYYY'
    if (step > 55) {
      date = moment().subtract((max - step), 'days')
      format = 'MMMM Do YYYY'
    } else if (step > 31 && step <= 55) {
      format = 'MMMM YYYY'
      date = moment().subtract((56 - step), 'months')
    } else if (step >= 1 && step <= 31) {
      date = moment().subtract((33 - step), 'years')
    } else {
      date = moment().subtract(32, 'years')
    }

    return { display: moment(date).format(format), value: moment(date).format('YYYY-MM-DD') }
  }

  getTimeSpan(start, end) {
    let e = moment(end + " 00:00:00","YYYY-MM-DD HH:mm:ss")
    let s = moment(start + " 00:00:00","YYYY-MM-DD HH:mm:ss")

    return e.to(s, true)
  }

  _onChange(val) {
    let start = this.getDateForStep(val)
    return this.setState({ values: { start } })
  }

  _saveAnswers() {
    const { onSave, values } = this.state
    onSave([{"value": values.start.value}])
  }

  render () {
    const { min, max, defaultValue, values } = this.state
    if (min && max && defaultValue) {
      return (
        <div className="form-horizontal slider slider-single">
          <div className="form-group">
            <div className="slider-value-container slider-value-container--top" data-label="Starting at:">
              <input type="text" value={values.start.display} readOnly />
            </div>
          </div>
          <div className="form-group">
            <div className="slider-row">
              <Slide min={min} max={max} defaultValue={defaultValue} onChange={this._onChange} />
            </div>
            <div className="slider-value-container slider-value-container--bottom">
              <span className="small">1968</span>
              <span className="small">Today</span>
            </div>
          </div>
          <button className="btn--done" onClick={() => {
              this._saveAnswers()
            }}>Done</button>
        </div>
      )
    }
    return null
  }
}

export default Slider
