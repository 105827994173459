import React from 'react'
import { observer, inject } from 'mobx-react'
import moment from 'moment'

import AutocompleteButton from 'components/AutocompleteButton'
import ButtonGroup from 'components/ButtonGroup'
import Slider from 'components/Slider'
import RangeSlider from 'components/RangeSlider'
import NoEntry from 'components/NoEntry'

type Props = {
  dogsStore: Object,
  id: Number,
  text: String,
  type: String,
  payload: Object,
  isUser: Boolean,
  answerTo: Number,
  isSessionMessage: Boolean,
  isAnswered: Boolean
}

@inject('dogsStore')
@observer class ChatBubble extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      id: "",
      text: "",
      type: "",
      payload: "",
      isUser: null,
      answerTo: null,
      isSessionMessage: false,
      isAnswered: false
    }

    this._saveAnswers = this._saveAnswers.bind(this)
  }

  componentDidMount () {
    this.setState({ 
      id: this.props.id,
      text: this.props.text,
      type: this.props.type,
      payload: this.props.payload,
      isUser: this.props.isUser,
      answerTo: this.props.answerTo,
      isSessionMessage: this.props.isSessionMessage,
      isAnswered: this.props.isAnswered
    })
  }

  // Answers

  _hasAnswers() {
    const { isAnswered } = this.state
    return isAnswered
  }

  _saveAnswers (answers) {
    const { id, isSessionMessage } = this.state
    const { dogsStore } = this.props
    dogsStore.saveAnswersById(id, answers)
      .then(() => {
        this.setState( { isAnswered: true } )
        dogsStore.triggeredTest++
        if (isSessionMessage) {
          dogsStore.fetchSession(answers[0].value)
        } else {
          dogsStore.fetchNext()
        }
      })
  }

  // Widgets & Display

  _getInnerWidget () {
    const { type, payload } = this.state
    if (!this._hasAnswers()) {
      switch(type) {
        case 'ButtonGroup':
          return (<ButtonGroup options={payload.options} onSave={this._saveAnswers} />)
        default:
          return null
      }
    }
    return null
  }

  _getOuterWidget () {
    const { id, type } = this.state
    if (!this._hasAnswers()) {
      let url
      switch(type) {
        case 'Autocomplete':
          url = `/form/autocomplete/${id}`
          return (<AutocompleteButton url={url} />)
        case 'MultiSelect':
          url = `/form/multiselect/${id}`
          return (<AutocompleteButton url={url} />)
        case 'NoEntry':
          return (<NoEntry onSave={this._saveAnswers} />)
        case 'Sortable':
          url = `/form/sortable/${id}`
          return (<AutocompleteButton url={url} />)
        case 'DateRangePicker':
          url = `/form/range_picker/${id}`
          return (<AutocompleteButton url={url} />)
        case 'DatePicker':
          url = `/form/date_picker/${id}`
          return (<AutocompleteButton url={url} />)
        case 'FreeForm':
          url = `/form/text/${id}`
          return (<AutocompleteButton url={url} />)
        case 'RestrictedNumber':
          url = `/form/restricted_number/${id}`
          return (<AutocompleteButton url={url} />)
        case 'Dosage':
          url = `/form/dosage/${id}`
          return (<AutocompleteButton url={url} />)
        case 'RangeSlider':
          return (<RangeSlider defaultValue={[1,85]} min={1} max={85} onSave={this._saveAnswers} />)
        case 'Slider':
          url = `/form/date_picker/${id}`
          return (<Slider defaultValue={85} min={1} max={85} onSave={this._saveAnswers} />)
        default:
          return null
      }
    }
    return null
  }

  _getText() {
    const { payload, type, text, isUser, answerTo } = this.state
    if (this._hasAnswers() && isUser && answerTo) {
      let newText = payload.answers.map((itm) => {
        let txt = (itm.text) ? itm.text : itm.name
        return '<div>' + txt + '</div>'
      })
      if (type === 'FreeForm') {
        return (payload.answers[0].value === '') ? 'None' : payload.answers[0].value
      } else if (type === 'RangeSlider' || type === 'DateRangePicker') {
        return payload.answers[0].display + ' to ' + payload.answers[1].display
      } else if (type === 'DatePicker') {
        return payload.answers[0].display
      } else if (type === 'RestrictedNumber') {
        return payload.answers[0].value + ' times a day'
      } else if (type === 'Slider' || type === 'text' || type === 'Dosage') {
        return (payload.answers[0].value === '') ? 'None' : payload.answers[0].value
      } else if (type === 'NoEntry') {
        return 'Ok'
      } else if (newText.length === 0) {
        return 'None'
      }
      return newText.join('')
    }
    return text
  }

  _getFormattedDate(date) {
    return moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY')
  }

  render () {
    return (
      <div>
        <div className="message_list__group__message">
          <div className="message_list__group__message__inner" dangerouslySetInnerHTML={{__html: this._getText()}}></div>
          {this._getInnerWidget()}
        </div>
        {this._getOuterWidget()}
      </div>
    )
  }
}

export default ChatBubble
