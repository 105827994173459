/* eslint prefer-promise-reject-errors: 0 */
import { payload } from 'lib/storybookData'

const messages = {
  fetchAllMessages: () => {
    return new Promise((resolve) => {
      return resolve(payload)
    })
  }
}

export default {
  messages
}
