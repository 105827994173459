import React from 'react'
import { Provider, observer } from 'mobx-react'
import { Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'
import innerHeight from 'ios-inner-height'
import stores from 'stores'
import Routes from 'routes'

import 'styles/index.scss'

type Props = {
  children: any
}

@observer class Core extends React.Component {
  props: Props
  constructor (props) {
    super(props)

    this.state = {
      previousPath: '',
      currentPath: window.location.pathname
    }

    window.previousPath = ''

    this._history = createBrowserHistory()
    this._history.listen((loc) => {
      const { currentPath } = this.state
      if (loc.pathname !== currentPath) {
        window.previousPath = currentPath
        this.setState({ previousPath: currentPath, currentPath: loc.pathname })
      }
    })
  }

  render () {
    console.log(innerHeight())
    var styles = {
      height: window.innerHeight
    }
    return (
      <Provider {...stores}>
        <Router history={this._history}>
          <main style={styles}>
            {this.props.children}
            <Routes />
          </main>
        </Router>
      </Provider>
    )
  }
}

export default Core