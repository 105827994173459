import React from 'react'
import ChatBubble from 'components/ChatBubble'

type Props = {
  groupId: String,
  isMine: Boolean,
  avatar: String,
  messages: Array,
  isLoading: Boolean
}

class ChatGroup extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      groupId: null,
      isLoading: false,
      isMine: false,
      avatar: null,
      messages: []
    }
  }

  componentDidMount () {
    if (this.props.groupId) this.setState( { groupId: this.props.groupId } )
    if (this.props.isMine) this.setState( { isMine: this.props.isMine } )
    if (this.props.avatar) this.setState( { avatar: this.props.avatar } )
    if (this.props.messages) this.setState( { messages: this.props.messages } )
    if (this.props.isLoading) this.setState( { isLoading: this.props.isLoading } )
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.groupId) this.setState( { groupId: nextProps.groupId } )
      if (nextProps.isMine) this.setState( { isMine: nextProps.isMine } )
      if (nextProps.avatar) this.setState( { avatar: nextProps.avatar } )
      if (nextProps.messages) this.setState( { messages: nextProps.messages } )
      if (nextProps.isLoading) this.setState( { isLoading: nextProps.isLoading } )
    }
  }
  _getAvatar () {
    const { avatar } = this.state
    if (avatar) {
      return (
        <div className="message_list__group__avatar">
          <img src={avatar} />
        </div>
      )
    } 
    return []
  }

  _getMessages () {
    const { groupId, messages } = this.state
    return messages.map(function (itm) {
      return (
        <ChatBubble key={`chat_bubble_${groupId}_${itm.id}`}  {...itm} />
      )
    })
  }

  _displayLoading() {
    const { isLoading } = this.state
    if (!isLoading) return null
    return (
      <div>
        <div className="message_list__group__message message_list__group__message--loading">
          <div className="message_list__group__message__inner"></div>
        </div>
      </div>
    )
  }

  render () {
    const { isMine } = this.state
    let classes = "message_list__group"
    if (isMine) {
      classes += " message_list__group--mine"
    }

    return (
      <div className={classes}>
        {this._getAvatar()}
        {this._getMessages()}
        {this._displayLoading()}
      </div>
    )
  }
}

export default ChatGroup
