import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  url: String
}

class AutocompleteButton extends React.Component {
  props: Props
  constructor (props) {
    super(props)

    this.state = {
      url: null
    }
  }

  componentDidMount () {
    if (this.props.url) this.setState( { url: this.props.url } )
  }

  render () {
    const { url } = this.state
    if (url) {
      return (
        <div className="multiActionButton">
          <Link
            to={url}
            className='multiActionButton--btn'
          ></Link>
        </div>
      ) 
    }
    return <div className="multiActionButton"></div>
  }
}

export default AutocompleteButton
