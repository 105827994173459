import React from 'react'

type Props = {
  text: String,
  options: Object,
  onSave: Function
}

class MultiSelect extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      onSave: null,
      text: null,
      options: null,
      selected: [],
      currentPadding: null
    }

    this._handleInputChange = this._handleInputChange.bind(this)
    this._detectScroll = this._detectScroll.bind(this)
  }

  componentDidMount () {
    this.setState( { currentPadding: null, text: this.props.text, options: this.props.options, onSave: this.props.onSave } )
  }

  componentDidUpdate() {
    const { currentPadding } = this.state
    if (!currentPadding) {
      this.setState( { currentPadding: this.question.offsetHeight } )
      document.querySelector('.multi_select__scroller').addEventListener("scroll", this._detectScroll)
      document.querySelector('.multi_select__container').classList.add('multi_select__container__scrollable')
      setTimeout(this._detectScroll, 250)
    }
  }

  _handleInputChange(event) {
    let { selected, options } = this.state
    let newItm = options.find((itm) => {
      return event.target.value === itm.value
    })
    if (newItm) {
      selected.push(newItm)
      this.setState({ selected })
    } else {
      throw new Error('Item not found: ' + event.target.value)
    }
  }

  _listOptions() {
    const { options } = this.state
    if (!options) return null
    let id, cl
    return options.map((itm) => {
      id = "itm_" + itm.value
      cl = "multi_select__option"
      cl += (itm.text.length > 20) ? " multiLine" : ""
      return (
        <div key={id} className={cl}>
          <input 
            type="checkbox" 
            id={id} 
            value={itm.value}
            onChange={this._handleInputChange} />
          <label htmlFor={id}>{itm.text}</label>
        </div>
      )
    })
  }

  _getCounts() {
    const { options, selected } = this.state
    if (selected && options) {
      return <div className="multi_select__selected">{selected.length} / {options.length} Items Selected</div>
    }
    return null
  }

  _saveAnswers() {
    const { onSave, selected } = this.state
    return onSave(selected)
  }

  _detectScroll() {
    const objDetect = document.querySelector('.multi_select__scroller')
    const objContainer = document.querySelector('.multi_select__container')
    if (objDetect.scrollHeight > objDetect.clientHeight) {
      if (objDetect.scrollTop <= 0) {
        objContainer.classList.add('multi_select__container__btmOn')
        objContainer.classList.remove('multi_select__container__topOn')
      } else if (objDetect.scrollTop === (objDetect.scrollHeight-objDetect.clientHeight)) {
        objContainer.classList.remove('multi_select__container__btmOn')
        objContainer.classList.add('multi_select__container__topOn')
      } else {
        objContainer.classList.add('multi_select__container__btmOn')
        objContainer.classList.add('multi_select__container__topOn')
      }
    }
  }

  render () {
    const { currentPadding, text } = this.state

    var divStyle = {
      paddingTop: currentPadding + 'px'
    }
    var topStyle = {
      top: divStyle.paddingTop
    }

    return (
      <div className="multi_select">
        <div className="multi_select__container">
          <div className="multi_select__options__top" style={topStyle}></div>
          <div className="multi_select__options__btm"></div>
          <div ref={(question) => { this.question = question }} className="multi_select__question" dangerouslySetInnerHTML={{__html: text}}></div>
          <div style={divStyle} className="multi_select__scroller">
            <div className="multi_select__options">
              {this._listOptions()}
            </div>
          </div>
        </div>
        <div className="multi_select__lower">
          {this._getCounts()}
          <button className="btn--save" onClick={() => {
            this._saveAnswers()
          }}>Done</button>
        </div>
      </div>
    ) 
  }
}

export default MultiSelect
