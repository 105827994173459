import React from 'react'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'

// https://github.com/clauderic/react-sortable-hoc

type Props = {
  text: String,
  options: Object,
  onSave: Function
}

class Sortable extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      text: null,
      options: null,
      onSave: null
    }

    this._onSortEnd = this._onSortEnd.bind(this)
  }

  componentDidMount () {
    this.setState( { text: this.props.text, options: this.props.options, onSave: this.props.onSave } )
  }

  _onSortEnd({oldIndex, newIndex}) {
    const { options } = this.state
    this.setState({
      options: arrayMove(options, oldIndex, newIndex)
    })
  }

  _saveAnswers() {
    const { onSave, options } = this.state
    onSave(options)
  }

  getElement(value) {
    return <li>{value}</li> 
  }

  render () {
    const { text, options } = this.state

    const SortableItem = SortableElement(({value}) =>
      <div className="sortable__item">{value}</div>
    )

    const SortableList = SortableContainer(({items}) => {
      return (
        <div className="sortable__container">
          {items.map((itm, index) => (
            <SortableItem key={`item-${index}`} index={index} value={itm.text} />
          ))}
        </div>
      )
    })
    
    if (options) {
      return (
        <div className="sortable">
          <div className="sortable__wrapper">
            <div className="multi_select__question" dangerouslySetInnerHTML={{__html: text}}></div>
            <SortableList 
              axis={"y"}
              lockAxis={"y"}
              lockToContainerEdges={true}
              items={options} 
              onSortEnd={this._onSortEnd} />
          </div>
          <button className="btn--save" onClick={() => {
            this._saveAnswers()
          }}>Done</button>
        </div>
      )
    }
    return null

  }
}

export default Sortable