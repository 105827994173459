import React from 'react'
import Textarea from 'react-textarea-autosize'

type Props = {
  question: String,
  options: Object,
  onSave: Function
}

class Freeform extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      question: null,
      options: null,
      textAnswer: "",
      displaySave: false,
      errorMessage: "",
      onSave: null
    }

    this._handleChange = this._handleChange.bind(this)
  }

  componentDidMount () {
    this.setState({ 
      question: this.props.question, 
      options: this.props.options, 
      onSave: this.props.onSave
    })
  }

  _handleChange(e) {
    const { displaySave } = this.state
    this.setState({ textAnswer: e.target.value })
    if (e.target.value === '') {
      if (displaySave) this.setState({ displaySave: false })
    } else {
      if (!displaySave) this.setState({ displaySave: true })
    }
  }

  _saveAnswers() {
    const { onSave, textAnswer, errorMessage } = this.state

    if (errorMessage === '') {
      if (onSave) {
        onSave([{ value: textAnswer }])
      } else {
        console.log(textAnswer)
      }
    }
  }

  render () {
    const { question } = this.state
    if (question) {
      return (
        <div className="modal freeForm">
          <div className="modal__container">
            <div ref={(question) => { this.question = question }} className="modal__question" dangerouslySetInnerHTML={{__html: question}}></div>
            <div className="modal__content">
              <Textarea onChange={this._handleChange} autoFocus />
            </div>
          </div>
          <div className="modal__lower">
          <button className="btn--save" onClick={(e) => {
            e.preventDefault()
            this._saveAnswers()
          }}>Done</button>
          </div>
        </div>
      ) 
    }
    return null
  }
}

export default Freeform