import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import Autocomplete from 'components/Autocomplete'
import DateSelector from 'components/DateSelector'
import Dosage from 'components/Dosage'
import FreeForm from 'components/Freeform'
import MultiSelect from 'components/MultiSelect'
import RestrictedNumber from 'components/RestrictedNumber'
import Sortable from 'components/Sortable'

type Props = {
  route: Object,
  history: Object,
  dogsStore: Object,
  match: Object
}

@inject('dogsStore')
@observer class Form extends Component {
  props: Props

  constructor (props) {
    super(props)

    this.state = {
      id: null,
      type: null,
      data: null,
      isSaving: false
    }

    this._saveAnswers = this._saveAnswers.bind(this)
  }
  
  componentWillMount () {
    this.setState({ isSaving: false })
    this.setState({ ...this.props.match.params })
  }

  componentDidMount() {
    const { id } = this.state
    const { dogsStore } = this.props
    let data = dogsStore.fetchItemById(id)
    this.setState({ data })
  }

  _saveAnswers (answers) {
    const { id, isSaving } = this.state
    const { history, dogsStore } = this.props
    if (!isSaving) {
      this.setState({ isSaving: true })
      dogsStore.saveAnswersById(id, answers)
        .then(() => {
          this.setState({ isSaving: false })
          history.push('/')
        })
    }
  }

  _getFormElement() {
    const { type, data } = this.state
    if (data) {
      if (type === 'autocomplete') {
        return <Autocomplete question={data.payload.question} options={data.payload.options} onSave={this._saveAnswers} />
      } else if (type === 'multiselect') {
        return <MultiSelect text={data.payload.question} options={data.payload.options} onSave={this._saveAnswers} />
      } else if (type === 'sortable') {
        return <Sortable text={data.payload.question} options={data.payload.options} onSave={this._saveAnswers} />
      } else if (type === 'date_picker') {
        return <DateSelector question={data.payload.question} isRange={false} onSave={this._saveAnswers} />
      } else if (type === 'range_picker') {
        return <DateSelector question={data.payload.question} isRange={true} onSave={this._saveAnswers} />
      } else if (type === 'restricted_number') {
        return <RestrictedNumber question={data.payload.question} onSave={this._saveAnswers} />
      } else if (type === 'dosage') {
        return <Dosage question={data.payload.question} onSave={this._saveAnswers} />
      } else if (type === 'text') {
        return <FreeForm question={data.payload.question} onSave={this._saveAnswers} />
      }
    }
    return null
  }

  render () {
    return (
      <div className="formArea">
        {this._getFormElement()}
      </div>
    )
  }
}

export default Form
