import React from 'react'

type Props = {
  onSave: Function
}

class AutocompleteButton extends React.Component {
  props: Props
  constructor (props) {
    super(props)

    this.state = {
      onSave: null
    }

    this._onClicked = this._onClicked.bind(this)
  }

  componentDidMount () {
    if (this.props.onSave) this.setState( { onSave: this.props.onSave } )
    setTimeout(this._onClicked, 3000)
  }

  _onClicked () {
    const { onSave } = this.state
    onSave([])
  }

  render () {
    return (
      <div className="noEntryButton">
        <a href="#" className="btn--continue" onClick={(e) => {
          e.preventDefault()
          this._onClicked()
        }}></a>
      </div>
    ) 
  }
}

export default AutocompleteButton
