import React from 'react'
import { observer, inject } from 'mobx-react'
import Select from 'react-select'

type Props = {
  dogsStore: Object
}

@inject('dogsStore')
@observer class Developer extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      selectedOption: ''
    }

    this._handleChange = this._handleChange.bind(this)
  }

  _handleChange (selectedOption) {
    const { dogsStore } = this.props
    this.setState({ selectedOption })
    dogsStore.injectType(selectedOption.value)
  }

  _handleClear () {
    const { dogsStore } = this.props
    dogsStore.clearStorage()
  }

  _handleRemoveLast () {
    const { dogsStore } = this.props
    dogsStore.removeLastMessage()
  }

  _handleSendPayload () {
    const { dogsStore } = this.props
    dogsStore.sendDeveloperPayload()
  }

  // Things that are missing.
  // - Dosage
  // - Text

  render () {
    const { selectedOption } = this.state
    const value = selectedOption && selectedOption.value

    return (
      <div className="developerMenu">
        <label>
          DEVELOPER OPTIONS
          <a href="#" onClick={(e) => { e.preventDefault(); this._handleClear() }}>Clear</a>
          <a href="#" onClick={(e) => { e.preventDefault(); this._handleRemoveLast() }}>Remove Last</a>
          <a href="#" onClick={(e) => { e.preventDefault(); this._handleSendPayload() }}>Send Payload</a>
        </label>
        <div className="developerMenu__dropdown">
          <Select
            name="form-field-name"
            value={value}
            onChange={this._handleChange}
            options={[
              { value: 'Autocomplete', label: 'Autocomplete' },
              { value: 'ButtonGroup_2', label: 'ButtonGroup (2 Options)' },
              { value: 'ButtonGroup_3', label: 'ButtonGroup (3 Options)' },
              { value: 'Dosage', label: 'Dosage' },
              { value: 'FreeForm', label: 'Freeform Text' },
              { value: 'MultiSelect', label: 'Multi Select' },
              { value: 'NoEntry', label: 'No Entry' },
              { value: 'RangeSlider', label: 'Range Slider' },
              { value: 'RestrictedNumber', label: 'Restricted Number' },
              { value: 'Slider', label: 'Slider' },
              { value: 'Sortable', label: 'Sortable' }
            ]}
          />
        </div>
      </div>
    )
  }
}

export default Developer