import { observable, action } from 'mobx'
import localStorage from 'mobx-localstorage'
import _ from 'lodash'
import api from './api'

import {
  buttonGenderQuestion,
  buttonGroupTwo,
  buttonGroupThree,
  autocomplete,
  multiSelectData,
  noEntryData,
  rangeSlider,
  sortableData,
  slider
} from 'lib/storybookData'

export default class DogsStore {
  @observable triggeredTest
  @observable currentMessage
  @observable allMessages

  constructor () {
    this.currentMessage = {}
    this.allMessages = []
    this.triggeredTest = 1
    this.firstContact = false
    this.readyToGetStarted = false
    this.sessionQuestion = false
    this.isActive = false
    this.isActiveCheck = 0
  }

  // Spec

  getNewId() {
    if (this.allMessages.length === 0) return 1
    let itm = this.allMessages.slice(-1)[0]
    return (Number(itm.id) + 1)
  }

  getNewSpec(params) {
    let obj = { 
      id: this.getNewId(),
      questionId: null,
      answerTo: null,
      text: "",
      delay: 500,
      type: "text",
      payload: {
        question: "",
        options: [],
        customArgs: [],
        answers: []
      },
      display: true,
      timestamp: Math.ceil(new Date().getTime() / 1000),
      isUser: false,
      isSystem: false,
      isHowAreYouTodayMessage: false,
      isGettingStartedMessage: false,
      isSessionMessage: false,
      isCompletedMessage: false,
      isAnswered: false
    }

    let newItm = _.extend({}, obj, params)
    return newItm
  }

  getQuestionType(type) {
    switch(type) {
      case 'multi_choice_dropdown':
        return 'Autocomplete'
      case 'multi_choice_checkbox':
        return 'MultiSelect'
      case 'date_slider_1':
        return 'DatePicker'
      case 'date_slider_2':
        return 'DateRangePicker'
      case 'priority_list':
        return 'Sortable'
      case 'single_choice':
        return 'ButtonGroup'
      case 'no_entry':
        return 'NoEntry'
      case 'restricted_number_1':
        return 'RestrictedNumber'
      case 'restricted_number_2':
        return 'Dosage'
      case 'text':
        return 'FreeForm'
      default:
        return 'text'
    }
  }

  setUserActive() {
    this.isActive = true
    this.isActiveCheck = 0
  }

  // Storage

  // TODO: Needs Promise
  saveChat() {
    localStorage.setItem('chatSomething', JSON.stringify(this.allMessages))
  }

  // TODO: Needs Promise
  clearStorage () {
    localStorage.removeItem("chatSomething")
    localStorage.removeItem("userToken")
  }

  // TODO: Needs Promise
  addItemToChat(itm) {
    this.allMessages.push(itm)
    this.triggeredTest++
    this.saveChat()
  }

  // TODO: Needs Promise
  filterSystemMessages(arr) {
    return arr.filter((itm) => {
      return itm.isSystem === false
    })
  }

  // TODO: Needs Promise
  removeLastMessage() {
    let itemString = localStorage.getItem('chatSomething')
    let json = JSON.parse(itemString)
    json.pop()
    this.allMessages = json
    this.saveChat()
  }

  getCurrentMessage() {
    return this.allMessages[this.allMessages.length-1]
  }

  // AI

  // TODO: Needs Button Group
  fetchGetStartedQuestion () {
    return { 
      text: "Are you ready to get started?",
      delay: 3000,
      type: "ButtonGroup",
      payload: {
        question: "Are you ready to get started?",
        options: [
          { text:"Yes", value:"1" },
          { text:"No", value:"0" }
        ],
        answers: []
      },
      isGettingStartedMessage: true,
      display: false
    }
  }

  fetchHowAreYouToday() {
    return { 
      text: "Hello, how are you today?",
      type: "ButtonGroup",
      payload: {
        question: "Hello, how are you today?",
        options: [
          { text:"Great!", value:"2" },
          { text:"Ok", value:"1" },
          { text:"Not Good :(", value:"0" }
        ],
        answers: []
      },
      isHowAreYouTodayMessage: true
    }
  }

  // TODO: Needs Promise
  doesQuestionExist(obj, status) {
    let foundItem
    if (status === 204) {
      foundItem = this.allMessages.find((itm) => {
        return (+itm.isCompletedMessage)
      })
    } else {
      foundItem = this.allMessages.find((itm) => {
        return (+itm.questionId === +obj.id && itm.text === obj.text)
      })
    }
    return (foundItem && typeof(foundItem.questionId) !== 'undefined')
  }

  // TODO: Needs Promise
  findIntroPosition() {
    this.allMessages.map((itm) => {
      // console.log('Is it: ', itm, itm.id, itm.text, itm.isUser, itm.isGettingStartedMessage)
      if (itm.isHowAreYouTodayMessage && itm.isUser) this.firstContact = true
      if (itm.isGettingStartedMessage && itm.isUser) this.readyToGetStarted = true
      if (itm.isSessionMessage && !itm.isUser) this.sessionQuestion = true
    })
  }

  @action injectAreYouThere () {
    // if (!this.isActive && this.isActiveCheck < 3) {
    //   let text = "Are you still there?"
    //   if (this.isActiveCheck === 1) {
    //     text = "Did you still want to talk?"
    //   } else if (this.isActiveCheck === 2) {
    //     text = "Ok, if you want to chat just refresh the page."
    //   }
    //   let params = { 
    //     text: text,
    //     isSystem: true
    //   }
    //   this.addItemToChat(this.getNewSpec(params))
    //   this.isActiveCheck++
    // }
  }

  // Actions

  // TODO: Needs Promise
  @action addUserTextEntry (id, text) {
    if (!this.firstContact) { // Specific start case...
      this.setUserActive()
      let params = { 
        text: text,
        isUser: true
      }
      this.addItemToChat(this.getNewSpec(params))
      if (!this.firstContact) {
        this.firstContact = true
        this.fetchNext()
      }
    } else {
      this.saveAnswersById(id, [{ value: text }], true)
    }
  }

  // TODO: Needs Promise
  @action setCurrent (id) {
    this.currentMessage = this.allMessages.find(function(itm) {
      return itm.id === +id
    })
  }

  // TODO: Needs Promise
  @action enableNextMessage () {
    const num = (this.allMessages.length - 1) // TODO: Find by ID instead
    this.allMessages[num].display = true
    this.triggeredTest = this.triggeredTest + 1
    this.saveChat()
  }

  // TODO: Needs Promise
  @action fetchAllMessages () {
    let userToken = localStorage.getItem('userToken')
    let itemString = localStorage.getItem('chatSomething')
    if (typeof(itemString) !== 'undefined' && itemString && itemString !== '') {
      this.allMessages = this.filterSystemMessages(JSON.parse(itemString))
      this.currentMessage = this.allMessages[(this.allMessages.length-1)]
      this.findIntroPosition()
      if (this.currentMessage.isSessionMessage && this.currentMessage.isUser && (!userToken || userToken === '')) {
        this.fetchSession()
      } else {
        this.fetchNext()
      }
    } else {
      this.fetchNext()
    }
    return Promise.resolve(this.allMessages)
  }

  @action fetchSession (gender) {
    return api.messages.fetchUserSession( {"gender": gender} )
      .then(({ data }) => {
        localStorage.setItem('userToken', JSON.stringify(data))
        return this.fetchNext()
      })
  }

  // TODO: Needs Promise
  @action fetchNext (delay = 0) {
    let userToken = localStorage.getItem('userToken')
    let itemString = localStorage.getItem('chatSomething')
    this.findIntroPosition()
    if (userToken && userToken !== '') {
      return api.messages.fetchNextMessage()
        .then(( { data, status } ) => {
          if ( !this.doesQuestionExist(data, status) ) {
            let params
            if (status === 204) {
              params = { isCompletedMessage: true, text: "Thank you for sharing your health information with us today. We look forward to seeing you in our office soon." }
            } else {
              let txt = data.text
              let helpText = (data.help_text) ? '<i class="help">' + data.help_text + '</i>' : ''
              if (data.question_type === 'no_entry') {
                txt = data.description
              }
  
              let arr = txt.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/g)
              if (arr) {
                arr = arr.map((itm) => { return { text: itm.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "") } })
                txt = arr.pop().text
                arr.map((itm) => { this.addItemToChat(this.getNewSpec(itm)) })
              }
  
              params = {
                questionId: data.id,
                type: this.getQuestionType(data.question_type),
                text: txt,
                delay: delay,
                payload: {
                  question: txt + helpText,
                  options: data.choices,
                  customArgs: data.custom_question_arguments,
                  answers: []
                },
                display: true
              }
            }
            this.addItemToChat(this.getNewSpec(params))
          }
        })
    } else if (!this.firstContact && !this.readyToGetStarted) {
      if (typeof(itemString) === 'undefined' || !itemString || itemString === '') {
        let params = this.fetchHowAreYouToday()
        this.addItemToChat(this.getNewSpec(params))
      }
    } else if (this.firstContact && !this.readyToGetStarted) {
      let params = this.fetchGetStartedQuestion()
      this.addItemToChat(this.getNewSpec(params))
    } else {
      if (this.firstContact && this.readyToGetStarted && !this.sessionQuestion) 
        this.addItemToChat(this.getNewSpec(buttonGenderQuestion))
      return Promise.resolve()
    }
  }

  // TODO: Needs Promise
  @action fetchItemById (id) {
    return this.allMessages.find(function(itm) {
      return itm.id === +id
    })
  }

  @action saveAnswersById (id, answers, fetchNextQuestion = false) {

    let current = this.allMessages.find(function(itm) {
      return (itm.id === +id)
    })
    
    let params = {
      "answer_choices": answers,
      "custom_question_arguments": current.payload.customArgs
    }

    if (current.isHowAreYouTodayMessage || current.isGettingStartedMessage || current.isSessionMessage) {
      let newMessages = this.allMessages.map((itm) => {
        if (itm.id === +id) {
          itm.payload.answers = answers
          itm.isAnswered = true
        }
        return itm
      })
      this.allMessages = newMessages
      this.buildUserCopyOfAnswer(id)
      return Promise.resolve()
    }

    return api.messages.saveAnswers(current.questionId, params)
      .then(() => {
        let newMessages = this.allMessages.map((itm) => {
          if (itm.id === +id) {
            itm.payload.answers = answers
            itm.isAnswered = true
          }
          return itm
        })
        this.allMessages = newMessages
        this.buildUserCopyOfAnswer(id)
        if (fetchNextQuestion) this.fetchNext()
      })
  }

  // TODO: Needs Promise
  buildUserCopyOfAnswer (id) {
    let data = this.allMessages.find(function(itm) { return itm.id === +id })
    let newObj = { 
      answerTo: +id,
      text: data.text,
      type: data.type,
      payload: data.payload,
      isUser: true,
      isSessionMessage: data.isSessionMessage,
      isGettingStartedMessage: data.isGettingStartedMessage,
      isHowAreYouTodayMessage: data.isHowAreYouTodayMessage,
      isAnswered: data.isAnswered
    }
    this.addItemToChat(this.getNewSpec(newObj))
  }

  // Developer Stuff

  @action sendDeveloperPayload() {
    let itemsObj = JSON.parse(localStorage.getItem('chatSomething'))
    let tokenObj = JSON.parse(localStorage.getItem('userToken'))

    // let items = itemsObj.filter((itm) => { return itm.isUser })

    let error = new Error('Developer Error Payload')
    Raven.captureException(error, { extra: { token: tokenObj.token, payload: itemsObj } })
    alert('Developer payload sent')
  }

  @action injectType (type) {
    this.setUserActive()
    this.firstContact = true
    return this.fetchType(type)
      .then((data) => {
        this.addItemToChat(this.getNewSpec(data))
      })
  }

  fetchType (type) {
    switch(type) {
      case 'Autocomplete':
        return this.getAutocomplete()
      case 'ButtonGroup_2':
        return this.getButtonGroup(2)
      case 'ButtonGroup_3':
        return this.getButtonGroup(3)
      case 'Slider':
        return this.getSlider()
      case 'RangeSlider':
        return this.getRangeSlider()
      case 'MultiSelect':
        return this.getMultiSelect()
      case 'NoEntry':
        return this.getNoEntry()
      case 'Sortable':
        return this.getSortable()
      case 'FreeForm':
        return this.getFreeForm()
    }
  } 

  getAutocomplete () {
    let copy = _.cloneDeep(autocomplete)
    delete(copy.id)
    return Promise.resolve(copy)
  }

  getButtonGroup(num) {
    if (num > 2) {
      let copy = _.cloneDeep(buttonGroupThree)
      delete(copy.id)
      return Promise.resolve(copy)
    } else {
      let copy = _.cloneDeep(buttonGroupTwo)
      delete(copy.id)
      return Promise.resolve(copy)
    }
  }

  getRangeSlider() {
    let copy = _.cloneDeep(rangeSlider)
    delete(copy.id)
    return Promise.resolve(copy)
  }

  getSlider() {
    let copy = _.cloneDeep(slider)
    delete(copy.id)
    return Promise.resolve(copy)
  }

  getMultiSelect() {
    let copy = _.cloneDeep(multiSelectData)
    delete(copy.id)
    return Promise.resolve(copy)
  }

  getNoEntry() {
    let copy = _.cloneDeep(noEntryData)
    let txt = copy.text
    if (copy.question_type === 'no_entry') {
      txt = copy.description
    }
    let params = {
      questionId: copy.id,
      type: this.getQuestionType(copy.question_type),
      text: txt,
      payload: {
        question: txt,
        options: copy.choices,
        customArgs: copy.custom_question_arguments,
        answers: []
      },
      display: true
    }
    return Promise.resolve(params)
  }

  getSortable() {
    let copy = _.cloneDeep(sortableData)
    delete(copy.id)
    return Promise.resolve(copy)
  }

  getFreeForm() {
    let params = { 
      text: "This is freeform text. Lets type something.",
      type: "FreeForm",
      payload: {
        question: "This is freeform text. Lets type something.",
        options: [],
        answers: []
      }
    }
    return Promise.resolve(params)
  }

}
