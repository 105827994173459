import React from 'react'

type Props = {
  onSave: Function,
  options: Object
}

class ButtonGroup extends React.Component {
  props: Props
  constructor (props) {
    super(props)
    this.state = {
      onSave: null,
      options: null
    }

    this._handleInputChange = this._handleInputChange.bind(this)
  }

  componentDidMount () {
    this.setState( { onSave: this.props.onSave, options: this.props.options } )
  }

  _handleInputChange (event) {
    let { onSave, options } = this.state
    let newItm = options.find((itm) => {
      return event.target.value === itm.value
    })
    if (newItm) {
      onSave([newItm])
    } else {
      throw new Error('Item not found: ' + event.target.value)
    }
  } 

  _getOptions () {
    const { options } = this.state
    if (!options) return null
    return options.map((itm) => {
      let name = 'button_' + itm.value
      return (
        <div key={name} className="buttonGroup__option">
          <input 
            type="radio" 
            id={name} 
            value={itm.value}
            onChange={this._handleInputChange} />
          <label htmlFor={name}>{itm.text}</label>
        </div>
      )
    })
  }

  render () {
    const { options } = this.state
    let classNames = 'buttonGroup'
    if (options && options.length > 0) {
      classNames += ' buttonGroup_' + options.length + 'up'
    }
    return (
      <div className={classNames}>
        {this._getOptions()}
      </div>
    )
  }
}

export default ButtonGroup
