import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from 'layouts/Home'
import Form from 'layouts/Form'
import NotFound from 'layouts/NotFound'

const routes = [
  {
    path: '/',
    exact: true,
    component: Home
  }, {
    path: '/form/:type/:id',
    component: Form
  }
]

const RouteWithSubRoutes = (route) => (
  <Route path={route.path} render={props => (
      // pass the sub-routes down to keep nesting
    <route.component {...props} test={route} routes={route.routes} />
    )} />
)

const Routes = () => (
  <Switch>
    {routes.map((route, i) => (
      <RouteWithSubRoutes key={i} {...route} />
     ))}
    <Route component={NotFound} />
  </Switch>
)

export default Routes
