import mockApi from './mockApi'
import localStorage from 'mobx-localstorage'

const host = process.env.API_HOST
const omitVersion = process.env.OMIT_API_VERSION === 'true'

// const addUserID = (endpoint, userId) => {
//   return endpoint.replace(':userId', userId)
// }

const http = (endpoint, options, isJson = true) => {
  return new Promise((resolve, reject) => {
    fetch(endpoint, options)
      .then((response) => {
        if (response.status === 204) {
          return resolve({ data: {}, status: response.status })
        } else if ((response.status > 399 && response.status < 410) || response.status >= 500) {
          alert(response.status + ' ' +response.statusText + ' ' + response.url +'. Try refreshing.')
          return reject({ status: response.status, error: response.statusText, url: response.url })
        } else {
          if (isJson) {
            response.json().then((data) => {
              if (response.ok) {
                return resolve({ data: data, status: response.status })
              } else {
                alert(response.status + ' ' +response.statusText + ' ' + response.url +'. Try refreshing.')
                return reject({ status: response.status, error: response.statusText, url: response.url })
              }
            })
          } else {
            if (response.ok) {
              return resolve({ data: {}, status: response.status })
            } else {
              alert(response.status + ' ' +response.statusText + ' ' + response.url +'. Try refreshing.')
              return reject({ status: response.status, error: response.statusText, url: response.url })
            }
          }
        }
      })
  })
}

const httpGet = (endpoint, version = 1, options = {}) => {
  return (omitVersion) ? http(host + endpoint, options) : http(host + '/v' + version + endpoint, options)
}

const httpGetAuth = (endpoint, version = 1) => {
  const token = localStorage.getItem('userToken')

  if (!token) { return Promise.reject(new Error('Unauthorized')) }

  let json = JSON.parse(token)

  return httpGet(`${endpoint}?token=${json.token}` , version)
}

const httpPost = (endpoint, payload, headers = {}, version = 1, isJson = true) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(payload)
  }

  return (omitVersion) ? http(host + endpoint, options, isJson) : http(host + '/v' + version + endpoint, options, isJson)
}

const httpPostAuth = (endpoint, payload, version = 1, isJson = true) => {
  const token = localStorage.getItem('userToken')

  if (!token) { return Promise.reject(new Error('Unauthorized')) }

  let json = JSON.parse(token)

  return httpPost(
    `${endpoint}?token=${json.token}`,
    payload,
    {},
    version,
    isJson
  )
}

// const httpPut = (endpoint, payload, headers = {}, version = 1) => {
//   const options = {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//       ...headers
//     },
//     body: JSON.stringify(payload)
//   }

//   return (omitVersion) ? http(host + endpoint, options) : http(host + '/v' + version + endpoint, options)
// }

// const httpPutAuth = (endpoint, payload, version = 1) => {
//   const jwt = window.localStorage.getItem('user')

//   if (!jwt) { return Promise.reject(new Error('Unauthorized')) }

//   const user = JSON.parse(jwt)

//   return httpPut(
//     addUserID(endpoint, user.user_id),
//     payload,
//     { 'Authorization': 'Bearer ' + user.token },
//     version
//   )
// }

// const httpDelete = (endpoint, headers = {}, version = 1) => {
//   const options = {
//     method: 'DELETE',
//     headers: {
//       ...headers
//     }
//   }

//   return (omitVersion) ? http(host + endpoint, options) : http(host + '/v' + version + endpoint, options)
// }

// const httpDeleteAuth = (endpoint, version = 1) => {
//   const jwt = window.localStorage.getItem('user')

//   if (!jwt) { return Promise.reject(new Error('Unauthorized')) }

//   const user = JSON.parse(jwt)

//   return httpDelete(
//     endpoint,
//     { 'Authorization': 'Bearer ' + user.token },
//     version
//   )
// }

const messages = {
  fetchUserSession: (data) => httpPost(`/sessions`, data, {}, 1),
  fetchNextMessage: () => httpGetAuth(`/questions/next`, 1),
  saveAnswers: (id, data) => httpPostAuth(`/questions/${id}/answers`, data, 1, false),
  fetchAllMessages: () => httpGet(`/breeds/list/all`, 1)
}
 
let api = {
  messages
}

if (!host || host === 'mocks') {
  api = mockApi
}

export default api
