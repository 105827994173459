
const payload = [
  { 
    id: 1,
    text: "Hello, how are you today?",
    delay: 0,
    type: "text",
    payload: {
      question: "",
      options: [],
      answers: []
    },
    display: true,
    timestamp: 1,
    isUser: false
  }
]

const buttonGenderQuestion = { 
  text: "Are you Male or Female?",
  delay: 3000,
  type: "ButtonGroup",
  payload: {
    question: "",
    options: [
      { text:"Male", value:"Male" },
      { text:"Female", value:"Female" }
    ],
    answers: []
  },
  display: false,
  isSessionMessage: true
}

const buttonGroupTwo = { 
  id: 1,
  text: "Just for a baseline, what color is the sky?",
  delay: 0,
  type: "ButtonGroup",
  payload: {
    question: "",
    options: [
      { text:"Red", value:"1" },
      { text:"Blue", value:"2" }
    ],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const buttonGroupThree = { 
  id: 1,
  text: "Just for a baseline, what color is the sky?",
  delay: 0,
  type: "ButtonGroup",
  payload: {
    question: "",
    options: [
      { text:"Red", value:"1" },
      { text:"Green", value:"2" },
      { text:"Blue", value:"3" }
    ],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const autocomplete = { 
  id: 1,
  text: "Let's do this auto complete.",
  delay: 0,
  type: "Autocomplete",
  payload: {
    question: "Please share the names of medications or supplements you are taking:",
    options: [
      { value:"headache", text:"Headache" },
      { value:"headache_migraine", text:"Headache - Migraine" },
      { value:"headache_tension", text:"Headache - Tension" },
      { value:"heartburn", text:"Heartburn" },
      { value:"head_thing", text:"Head Thing" },
      { value:"deheader", text:"Deheader" },
      { value:"anxiety", text:"Anxiety" },
      { value:"fibromylagia", text:"Fibromylagia" },
      { value:"difficulty_concentrating", text:"Difficulty Concentrating" }
    ],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const multiSelectData = { 
  id: 1,
  text: "Let's do this multi select.",
  delay: 0,
  type: "MultiSelect",
  payload: {
    question: "Do you <strong>currently experience</strong> and of the following? <em>Select all that apply.</em>",
    options: [
      { value:"headache", text:"Headache" },
      { value:"headache_migraine", text:"Headache - Migraine" },
      { value:"headache_tension", text:"Headache - Tension" },
      { value:"heartburn", text:"Heartburn" },
      { value:"head_thing", text:"Head Thing" },
      { value:"deheader", text:"Deheader" },
      { value:"anxiety", text:"Anxiety" },
      { value:"fibromylagia", text:"Fibromylagia" },
      { value:"difficulty_concentrating", text:"Difficulty Concentrating" },
      { value:"fatigue", text:"Fatigue" },
      { value:"cough", text:"Cough" },
      { value:"hay_fever", text:"Hay Fever" },
      { value:"nausea", text:"Nausea" },
      { value:"night_sweats", text:"Night Sweats" },
      { value:"weight_gain", text:"Weight Gain" }
    ],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const sortableData = { 
  id: 1,
  text: "Let's do this sortable.",
  delay: 0,
  type: "Sortable",
  payload: {
    question: "Do you <strong>currently experience</strong> and of the following? <em>Select all that apply.</em>",
    options: [
      { value:"headache", text:"Headache" },
      { value:"headache_migraine", text:"Headache - Migraine" },
      { value:"headache_tension", text:"Headache - Tension" },
      { value:"heartburn", text:"Heartburn" },
      { value:"head_thing", text:"Head Thing" },
      { value:"deheader", text:"Deheader" }
    ],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const rangeSlider = { 
  id: 1,
  text: "Let's do this slider.",
  delay: 0,
  type: "RangeSlider",
  payload: {
    question: "",
    options: [],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const slider = { 
  id: 1,
  text: "Let's do this slider.",
  delay: 0,
  type: "Slider",
  payload: {
    question: "",
    options: [],
    answers: []
  },
  display: true,
  timestamp: 6,
  isUser: false
}

const noEntryData = {
  "id":4,
  "question_type":"no_entry",
  "text":"",
  "description":"Ok, let's quickly gather some information around a few specific systems in your body, and understand if you have ever experienced any other illnesses. ",
  "custom_question_arguments":[],
  "choices":[]
}

export {
  payload,
  buttonGenderQuestion,  
  buttonGroupTwo,
  buttonGroupThree,
  autocomplete,
  multiSelectData,
  noEntryData,
  rangeSlider,
  sortableData,
  slider
}