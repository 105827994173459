import React from 'react'
import moment from 'moment'
import Picker from 'rmc-picker'

type Props = {
  index: String,
  displayPicker: Boolean,
  label: String,
  value: String,
  onUpdate: Function
}

class AilmentPicker extends React.Component {
  props: Props
  constructor(props) {
    super(props)
    this.state = {
      indexc: null,
      label: "",
      disabled: false,
      items: this._getItems(),
      value: '85',
      onUpdate: null
    }

    this._onChange = this._onChange.bind(this)
  }

  componentDidMount() {
    if (this.props.index) this.setState({ index: this.props.index })
    if (this.props.label) this.setState({ label: this.props.label })
    if (this.props.value) this.setState({ value: +this.props.value })
    if (this.props.onUpdate) this.setState({ onUpdate: this.props.onUpdate })
  }

  _onChange(value) {
    const { index, onUpdate } = this.state
    
    this.setState({ value })
    if (onUpdate) {
      let date = this._getDateForStep(+value)
      date.value = this._getProperAnswerDate(date).format('YYYY-MM-DD')
      onUpdate(index, date)
    }
  }

  _togglePicker() {
    const { displayPicker } = this.state
    let newVal = (displayPicker) ? false : true
    this.setState({ displayPicker: newVal })
  }

  _getValue() {
    const { value } = this.state
    let chosenDate = this._getDateForStep(value)
    return `${chosenDate.display}`
  }

  _getDateForStep(step) {
    const max = 85

    let date
    let format = 'YYYY'
    if (step > 55) {
      date = moment().subtract((max - step), 'days')
      format = 'MMMM D, YYYY'
    } else if (step > 31 && step <= 55) {
      format = 'MMMM YYYY'
      date = moment().subtract((56 - step), 'months')
    } else if (step >= 1 && step <= 31) {
      date = moment().subtract((33 - step), 'years')
    } else {
      date = moment().subtract(32, 'years')
    }

    return { display: moment(date).format(format), value: moment(date).format('YYYY-MM-DD') }
  }

  _getProperAnswerDate(answer) {
    let arrChk = answer.display.split(' ')
    if (arrChk.length === 1) {
      return moment(answer.display + '-01-01', "YYYY-MM-DD")
    } else if (arrChk.length === 2) {
      return moment(arrChk[0] + ' 1 ' + arrChk[1], "MMMM D YYYY")
    }
    return moment(answer.display, "MMMM D, YYYY")
  }

  _getItems() {
    let i
    let arr = []
    for (i = 1; i <= 85; i++) 
      arr.push({ id: i, date: this._getDateForStep(i) })

    return arr.reverse().map((itm, idx) => {
      return <Picker.Item key={`date_${idx}`} className="my-picker-view-item" value={itm.id}>{itm.date.display}</Picker.Item>
    })
  }

  _getPicker() {
    const { displayPicker, items, value } = this.state
    
    if (displayPicker) {
      return (
        <Picker
          selectedValue={value}
          onValueChange={this._onChange}
        >
          {items}
        </Picker>
      )
    }
    return null
  }

  render() {
    const { label, displayPicker } = this.state

    return (
      <div className={`datePicker datePicker--on ${displayPicker ? "datePicker--on" : ""}`}>
        <div className="datePicker__date">
          <a href="#" onClick={(e) => {
            e.preventDefault()
            this._togglePicker()
          }}>
            <span>{label}: <strong>{this._getValue()}</strong></span>
            <i className="fas fa-chevron-down"></i>
          </a>
        </div>
        <div className="datePicker__picker">
          {this._getPicker()}
        </div>
      </div>
    )
  }
}

export default AilmentPicker