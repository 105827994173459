import React, { Component } from 'react'

class NotFound extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <section className='oopsies'>
        Not found!
      </section>
    )
  }
}

export default NotFound
