import React from 'react'
import Autosuggest from 'react-autosuggest'
import _ from 'lodash'

// https://github.com/moroshko/react-autosuggest

type Props = {
  question: String,
  options: Object,
  onSave: Function
}

class Autocomplete extends React.Component {
  props: Props
  constructor() {
    super()
    this.state = {
      onSave: null,
      question: null,
      selectedValue: "",
      finalValues: [],
      value: "",
      options: [],
      suggestions: [],
      displayFinish: false,
      displayTextOnly: false
    }
    
    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.getSuggestions = this.getSuggestions.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
    this.whichAnimationEvent = this.whichAnimationEvent.bind(this)
    this.setListener = this.setListener.bind(this)
    this.customFunction = this.customFunction.bind(this)
    this.removeOption = this.removeOption.bind(this)
    this.getSuggestionValue = this.getSuggestionValue.bind(this)
    this._handleTextChange = this._handleTextChange.bind(this)
    this._doTextOnlySubmit = this._doTextOnlySubmit.bind(this)
    this._hardReturnAdd = this._hardReturnAdd.bind(this)
  }

  ucwords(str) {
    return str.toLowerCase().replace(/\b[a-z]/gi, function(letter) {
        return letter.toUpperCase()
    })
  }

  componentDidMount () {
    if (this.props.question) this.setState( { question: this.props.question } )
    if (this.props.options) this.setState( { options: this.filterOptions(this.props.options) } )
    if (this.props.onSave) this.setState( { onSave: this.props.onSave } )
    this.setListener()
  }

  filterOptions(options) {
    return options.filter((itm) => { return (itm.text && itm.value) })
  }

  onSuggestionSelected(event, { suggestion }) {
    this.setState({value: "", selectedValue: suggestion})
  }

  _doTextOnlySubmit(e) {
    e.preventDefault()
    const { value } = this.state
    this.setState({value: "", selectedValue: {value: value, text: value}})
  }

  onChange(event, { newValue }) {
    this.addOwn = newValue
    this.setState({ value: newValue })
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({ suggestions: this.getSuggestions(value) })
  }

  getSuggestions(value) {
    const { options, displayTextOnly } = this.state
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const regex = new RegExp(inputValue, "gi")

    let suggestions = inputLength === 0 ? [] : options.filter((opt) => {
      return opt.text.match(regex)
    })

    if (suggestions.length === 0) {
      if (!displayTextOnly) this.setState({ displayTextOnly: true })
      return []
    }
    if (displayTextOnly) this.setState({ displayTextOnly: false })

    return suggestions.splice(0, 5).map((itm) => {
      itm.text = this.ucwords(itm.text)
      return itm
    })
  }

  _moveCaretAtEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] })
  }

  getSuggestionValue(suggestion) {
    return this.ucwords(suggestion.text)
  }

  renderSuggestion(suggestion) {
    const { value } = this.state
    const { text } = suggestion
    const regex = new RegExp(value, "gi")
    const newName = text.replace(regex, '<span>' + value + '</span>')
    return (
      <div dangerouslySetInnerHTML={{__html: newName}}></div>
    )
  }

  whichAnimationEvent(){
    var t,
        el = document.createElement("fakeelement")
  
    var animations = {
      "animation"      : "animationend",
      "OAnimation"     : "oAnimationEnd",
      "MozAnimation"   : "animationend",
      "WebkitAnimation": "webkitAnimationEnd"
    }
  
    for (t in animations){
      if (el.style[t] !== undefined){
        return animations[t]
      }
    }
  }

  _hardReturnAdd(e) {
    const { options } = this.state
    if (e.keyCode === 13) {
      const obj = document.querySelector(".react-autosuggest__input")
      if (obj.value !== "") {
        let suggestion = options.find((itm) => { console.log(itm.value, obj.value); return itm.value === obj.value.toLowerCase() })
        if (!suggestion) {
          suggestion = {value: obj.value, text: obj.value}
        }
        this.setState({value: "", selectedValue: suggestion})
      }
    }
  }

  setListener() {
    var animationEvent = this.whichAnimationEvent()
    var button = document.querySelector(".selectedSuggestion")
    button.addEventListener(animationEvent, this.customFunction)

    document.querySelector(".react-autosuggest__input").addEventListener('keyup', this._hardReturnAdd)
  }

  customFunction() {
    let { selectedValue, finalValues } = this.state
    finalValues.push(selectedValue)
    this.setState({selectedValue: "", finalValues})
    var button = document.querySelector(".selectedSuggestion")
    button.className = 'selectedSuggestion'
  }

  viewFinalList() {
    const { displayFinish, finalValues } = this.state
    if (finalValues.length > 0) {
      const changedOption = (displayFinish) ? false : true
      this.setState({ displayFinish: changedOption })
    } else {
      alert('Error: Please choose some options.')
    }
  }

  renderSelectedList() {
    const { finalValues } = this.state
    let i = 0
    return finalValues.map((itm) => {
      i++
      return (
        <div key={`autocomple_list_${i}`} className="autocomplete__selected__option">
          <a href="#" onClick={(e) => {
            e.preventDefault()
            this.removeOption(itm.value)
          }}><i></i></a>
          {this.ucwords(itm.value)}
        </div>
      )
    })
  }

  removeOption(val) {
    let { finalValues } = this.state
    let newList = _.remove(finalValues, (itm) => {
      return itm.value !== val
    })
    let displayIt = (newList.length > 0) ? true : false
    this.setState({ finalValues: newList, displayFinish: displayIt })
  }

  saveAnswers() {
    const { onSave, finalValues } = this.state
    if (finalValues.length > 0) {
      return onSave(finalValues)
    }
    alert('Error: Please choose some options.')
    return null
  }

  _getSaveButton() {
    const { finalValues, displayFinish } = this.state

    let text = 'Done'
    if (displayFinish) {
      text = 'Save'
    }

    if (finalValues.length > 0) {
      return (
        <button className="btn--save" onClick={(e) => {
          e.preventDefault()
          return (displayFinish) ? this.saveAnswers() : this.viewFinalList()
        }}>{text}</button>
      )
    }
    return null
  }

  _handleTextChange(e) {
    this.setState({value: e.target.value})
    this.getSuggestions(e.target.value)
  }

  _getCurrentTextBox() {
    const { value, suggestions, displayTextOnly } = this.state

    if (displayTextOnly) {
      return (
        <div className="autocomplete__textonly__input">
          <form id="textOnlyForm" onSubmit={this._doTextOnlySubmit}>
            <input type="text" id="addOwn" name="addOwn" autoComplete="off" value={value} autoFocus onFocus={this._moveCaretAtEnd} onChange={this._handleTextChange} /> 
          </form>
        </div>
      )
    }

    const inputProps = {
      placeholder: 'Start typing...',
      value,
      onChange: this.onChange,
      autoFocus: true,
      onFocus: this._moveCaretAtEnd
    }

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
      />
    )
  }

  render() {
    const { question, finalValues, selectedValue, displayFinish, displayTextOnly } = this.state
    

    let mainClassName = 'autocomplete'
    if (displayFinish) {
      mainClassName += ' autocomplete--verify'
    }

    let animationClassName = 'selectedSuggestion'
    if (selectedValue) {
      animationClassName += ' selectedSuggestion--on'
    }

    return (
      <div className={mainClassName}>
        <button className="autocomplete__counter" onClick={(e) => { e.preventDefault() }}><span>{finalValues.length}</span></button>
        <div className="autocomplete__container">
          <div className={`autocomplete__container__interface ${displayTextOnly ? "autocomplete__container__interface__textOnly" : ""}`}>
            <div className="autocomplete__title">{question}</div>
            {this._getCurrentTextBox()}
          </div>
          <div className="autocomplete__selected">
            <div className="autocomplete__selected__options">
              {this.renderSelectedList()}
            </div>
          </div>
        </div>
        {this._getSaveButton()}
        <div className={animationClassName}>
          {selectedValue.value}
        </div>
      </div>
    ) 
  }
}

export default Autocomplete